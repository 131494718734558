<template>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
</template>
<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
    components: {
        OverlayProgress
    },
    created() {
        console.log('redirecting link')
        window.location = `https://drive.google.com/drive/folders/1Yx_di4LCmMNCRt5DPYTAwiUFNkLWlN_e?usp=sharing`
    }
}
</script>